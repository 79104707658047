import { isPlatformServer } from '@angular/common';
import { Directive, Inject, OnInit, PLATFORM_ID, TemplateRef, ViewContainerRef } from '@angular/core';

/**
 * @example Example usage:
 * <clock *ssr-norender></clock>
 */

@Directive({
  selector: '[ssr-norender]',
  standalone: true
})
export class SsrNorenderDirective implements OnInit {
  /**
   * The constructor
   */
  constructor(@Inject(PLATFORM_ID) private platformId, private viewContainer: ViewContainerRef, private templateRef: TemplateRef<any>) {
  }

  /**
   * renders a template whether a platform id does not represent the server platform.
   */
  ngOnInit() {
    if (isPlatformServer(this.platformId)) {
      this.viewContainer.clear();
    } else {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }

}
